import * as monitoring from './monitoring'

/**
 * @param promiseFn Function returning a promise
 * @param maxTries How many times promiseFn can be called at maximum (1 try is the same as not using this util)
 * @param delayMs Time to wait before retrying after a failed attempt (no waiting by default)
 * @param condition (Optional) Condition to satisfy if the promise resolves but has unexpected result
 * @param message (Optional) Message to add to the info logging
 * @param triesCount (Optional) Just for internal logging purposes
 * @returns {Promise<*>}
 */
async function retryPromise(
    promiseFn,
    {
        triesCount = 1,
        maxTries = 2,
        delayMs = 0,
        condition = () => true,
        message,
        previousError
    } = {}
) {
    try {
        const res = await promiseFn()
        if (!condition(res)) {
            throw new Error(`Condition in "${message}" was not satisfied`)
        }

        if (triesCount > 1) {
            monitoring.log(`Retry suceeded at ${triesCount} time, message: ${message}`, {tags: {previousError: previousError && previousError.toString()}})
        }

        return res
    } catch (err) {
        if (maxTries <= 1) {
            if (typeof err === 'string') {
                throw new Error('Failed after multiple retries: ' + err)
            } else {
                err.message = `Failed after multiple retries: ${err.message}`
                throw err
            }
        }
        if (delayMs > 0) {
            await new Promise(resolve => setTimeout(resolve, delayMs))
        }

        return retryPromise(promiseFn, {maxTries: maxTries - 1, delayMs, triesCount: triesCount + 1, condition, message, previousError: err})
    }
}

function allSettled(promises) {
    var savedError

    const saveFirstError = error => {
        if (!savedError) {
            savedError = error
        }
    }

    const handleErrors = value => Promise.resolve(value).catch(saveFirstError)

    const allSettledPromises = Promise.all(promises.map(handleErrors))

    return allSettledPromises.then(function (resolvedPromises) {
        if (savedError) {
            throw savedError
        }
        return resolvedPromises
    })
}

export {retryPromise, allSettled}
