import Raven from 'raven-js'
import {create as createFedopsLogger} from '@wix/fedops-logger'

const RAVEN_INIT_STRING = 'https://d71c47c01a6e4b2a9c1cf460e3f76627@sentry.wixpress.com/27'
const FEDOPS_APP_NAME = 'santa-members-editor-app'

let fedopsInstance

async function initializeMonitoring(editorSDK, options = {}) {
    const [metaSiteId, siteRegion, siteLanguage] = await Promise.all([
        editorSDK.info.getMetaSiteId(),
        editorSDK.info.getSiteRegion(),
        editorSDK.info.getLanguage(),
    ])
    const tags = {
        metaSiteId,
        siteRegion,
        siteLanguage,
    }
    if (typeof options.firstInstall !== 'undefined') {
        tags.firstInstall = options.firstInstall
    }
    if (options.origin && options.origin.type) {
        tags.type = options.origin.type
    }
    Raven.config(RAVEN_INIT_STRING, {tags}).install()
    fedopsInstance = createFedopsLogger(FEDOPS_APP_NAME)
}

function interactionStarted(interactionName) {
    try {
        fedopsInstance.interactionStarted(interactionName)
    } catch (e) {
        const err = 'Failed to start fedops interaction, reason: ' + e
        Raven.captureException(err)
    }
}

function interactionEnded(interactionName) {
    try {
        fedopsInstance.interactionEnded(interactionName)
    } catch (e) {
        const err = 'Failed to end fedops interaction, reason: ' + e
        Raven.captureException(err)
    }
}

function interactionFailed(interactionName, err) {
    Raven.captureException(err, {tags: {interactionName}})
}

async function toMonitored(interactionName, promise) {
    try {
        interactionStarted(interactionName)
        const response = await promise()
        interactionEnded(interactionName)
        return response
    } catch (err) {
        interactionFailed(interactionName, err)
        throw err
    }
}

function log(message, options = {}) {
    Raven.captureMessage(message, {level: 'info', ...options})
}

export {initializeMonitoring, interactionStarted, interactionEnded, interactionFailed, toMonitored, log}
